var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{class:{
    'page-section': true,
    [`is-bg-${_vm.bgStyle}`]: true,
    'is-above': _vm.above,
    'is-overflow-hidden': _vm.overflowHidden,
    'is-only-section': _vm.onlySection
  }},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }