<template>
  <div class="team-member">
    <div class="team-member__inner">
      <div class="team-member__photo" :style="{ backgroundImage: `url('${photoUrl}')` }" />
      <div class="team-member__info">
        <h5><slot name="name" /></h5>
        <p><slot name="title" /></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    photoUrl: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="sass">
.team-member
  border-radius: $borderRadius
  background-color: $white
  +tileShadow
  +padding('sm')
  width: 100%
  height: 100%
  font-size: 120%
  cursor: pointer
  box-shadow: 0 0 0 0 $red
  transition: all $tsxDurationQuick $tsxEasing
  +hover
    color: $red
    box-shadow: 0 0 0 4px $red
    transform: scale(1.05)
    .team-member__inner
      transform: scale(0.95)

.team-member__inner
  width: 100%
  height: 100%
  display: flex
  align-items: center
  transition: transform $tsxDurationQuick $tsxEasing

.team-member__photo
  width: 5rem
  height: 5rem
  background-color: #ccc
  border-radius: 1000px
  background-repeat: no-repeat
  background-size: cover
  background-position: 50% 50%
  +margin(0, 'sm', 0)

.team-member__info
  p
    line-height: 1.5
    &:nth-child(2)
      opacity: 0.6
      font-size: 90%

</style>
