<template>
  <component :is="tagName" :to="to" :href="href" :target="target" class="nav-link is-link">
    <slot />
  </component>
</template>

<script>
import link from '@/mixins/link'

export default {
  mixins: [
    link
  ]
}
</script>

<style lang="sass">
.nav-link
  color: $white
  display: inline-flex
  cursor: pointer
  &.router-link-exact-active
    color: $red
</style>
