<template>
  <component :is="tagName" :href="href" :target="target" class="info-tile">
    <slot />
  </component>
</template>

<script>
export default {
  props: {
    href: {
      type: [String, undefined],
      default: undefined
    },

    target: {
      type: [String, undefined],
      default: ''
    }
  },

  computed: {
    tagName () {
      return this.href
        ? 'a'
        : 'div'
    }
  }
}
</script>

<style lang="sass">
.info-tile
  display: block
  position: relative
  transition: transform $tsxDurationQuick $tsxEasing
  width: 100%
  height: 100%

a.info-tile
  cursor: pointer
  color: $fontColor
</style>
