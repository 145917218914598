const pkg = require('../package.json')

module.exports = {
  meta: {
    siteName: pkg.title,
    titleTemplate: `%s | ${pkg.title}`,
    defaultDescription: 'An AI powered gaming company',
    previewImageUrl: '/sharePromo.jpg',
    faviconUrl: '/favicon.ico',
    touchIconUrl: '/logo.png'
  },
  lang: {
    default: 'en',
    supported: ['en']
  },
  breakpoints: {
    phone: 320,
    tablet: 768,
    desktop: 1024,
    highdef: 1680
  },
  colors: {
    red: '#E53D30',
    darkRed: '#9A1C1C',
    blue: '#3784D3',
    darkBlue: '#003C79',
    black: '#171717',
    white: '#fff'
  },
  content: {
    featuredGames: [
      'littleBigSnake',
      'mopeIo',
      'diepIo',
      'devastIo',
      'evIo',
      'starveIo',
      'tacticsCore',
      'kugelnIo',
      'littlewargame'
    ]
  }
}
