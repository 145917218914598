<template>
  <div class="youtube-container">
  </div>
</template>

<script>
import videoEventBus from '@/lib/videoEventBus'

export default {
  props: {
    src: {
      type: String,
      required: true
    },

    width: {
      type: Number
    },

    height: {
      type: Number
    }
  },

  created () {
    this.pauseHandler = () => this.pause()
    videoEventBus.addEventListener('play', this.pauseHandler)
    videoEventBus.addEventListener('pauseAll', this.pauseHandler)
  },

  beforeDestroy () {
    videoEventBus.removeEventListener('play', this.pauseHandler)
    videoEventBus.removeEventListener('pauseAll', this.pauseHandler)
  },

  mounted () {
    console.log('iframe found?', this.$el.querySelector('iframe'))
    if (this.$el.querySelector('iframe') !== null) return

    const player = document.createElement('iframe')
    player.border = 0
    player.allowFullscreen = true
    player.width = this.width || 800
    player.height = this.height || 450
    player.src = `${this.src}?enablejsapi=1&rel=0`

    this.$el.appendChild(player)
  },

  methods: {
    pause () {}
  }
}
</script>

<style lang="sass">
.youtube-container
  width: 100%
  display: flex
  background: #000
  border-radius: $borderRadius
  position: relative
  overflow: hidden
  cursor: pointer
</style>
