<template>
  <component
    :is="headingTag"
    :class="{ 'page-section-heading': true, [`is-color-${color}`]: true }"
  >
    <template v-if="text">
      <span v-html="headingSpans" />
    </template>
    <template v-else>
      <slot />
    </template>
  </component>
</template>

<script>
import anime from 'animejs'

export default {
  props: {
    level: {
      type: Number,
      default: 2
    },

    color: {
      type: String,
      default: 'red'
    },

    text: {
      type: String,
      default: 'red'
    }
  },

  data () {
    return {
      loadHandler: null
    }
  },

  computed: {
    headingTag () {
      return `h${this.level}`
    },

    headingSpans () {
      return this.text
      // return this.text
      //   ? this.text.replace(/\S/g, '<span class=\'letter\'>$&</span>')
      //   : ''
    }
  },

  beforeDestroy () {
    // window.removeEventListener('load', this.loadHandler)
  },

  mounted () {
    // if (!process.browser || !this.text) return

    // window.addEventListener('load', this.loadHandler = () => {
    //   this.animateText();
    // });
  },

  methods: {
    animateText () {
      anime
        .timeline()
        .add({
          targets: this.$el.querySelectorAll('.letter'),
          scale: [4, 1],
          opacity: [0, 1],
          translateZ: 0,
          easing: 'easeOutExpo',
          duration: 950,
          delay: (el, i) => 70 * i + 1000
        })
    }
  }
}
</script>

<style lang="sass">
.page-section-heading
  color: $lightFontColor
  font-family: $titleFont
  font-weight: $titleFontWeight
  font-size: $titleFontSize
  line-height: 1.1
  text-transform: uppercase
  letter-spacing: 1px
  +margin(0, 0, 'sm')

  .letter
    display: inline-block
    // opacity: 0

  &.is-color-black
    text-shadow: 0 0.125em 0 rgba($black, 0.6)

h1.page-section-heading
  font-size: $heroTitleFontSize

h3.page-section-heading
  font-size: 150%
</style>
