<template>
  <blank bgStyle="chalkboard" class="get-in-touch-section" heading-color="red">
    <template v-slot:title><locale :path="localePath('title')" /></template>
    <template v-slot:content>
      <info-tile-grid>
        <info-tile-grid-item>
          <info-tile>
            <info-tile-content>
              <template v-slot:title>
                <locale :path="localePath('developersTile.title')" />
              </template>
              <template v-slot:text>
                <locale :path="localePath('developersTile.text')" />
              </template>
              <template v-slot:control>
                <info-tile-link
                  :href="$locale(localePath('developersTile.href'))"
                  :icon="$locale(localePath('developersTile.icon'))"
                  target="_blank"
                >
                  <locale :path="localePath('developersTile.linkText')" />
                </info-tile-link>
              </template>
            </info-tile-content>
          </info-tile>
        </info-tile-grid-item>
        <!-- <info-tile-grid-item>
          <info-tile>
            <info-tile-content>
              <template v-slot:title>
                <locale :path="localePath('careersTile.title')" />
              </template>
              <template v-slot:text>
                <locale :path="localePath('careersTile.text')" />
              </template>
              <template v-slot:control>
                <info-tile-link
                  :to="$locale(localePath('careersTile.to'))"
                  :icon="$locale(localePath('careersTile.icon'))"
                >
                  <locale :path="localePath('careersTile.linkText')" />
                </info-tile-link>
              </template>
            </info-tile-content>
          </info-tile>
        </info-tile-grid-item> -->
        <info-tile-grid-item>
          <info-tile>
            <info-tile-content>
              <template v-slot:title>
                <locale :path="localePath('advertisingTile.title')" />
              </template>
              <template v-slot:text>
                <locale :path="localePath('advertisingTile.text')" />
              </template>
              <template v-slot:control>
                <info-tile-link
                  :href="$locale(localePath('advertisingTile.href'))"
                  :icon="$locale(localePath('advertisingTile.icon'))"
                  target="_blank"
                >
                  <locale :path="localePath('advertisingTile.linkText')" />
                </info-tile-link>
              </template>
            </info-tile-content>
          </info-tile>
        </info-tile-grid-item>
        <!-- <info-tile-grid-item>
          <info-tile>
            <info-tile-image imageSrc="/teamPhoto1.jpg" />
          </info-tile>
        </info-tile-grid-item>
        <info-tile-grid-item>
          <info-tile>
            <info-tile-image imageSrc="/teamPhoto2.jpg" />
          </info-tile>
        </info-tile-grid-item> -->
      </info-tile-grid>
      <contact-icons class="get-in-touch-section__icons" />
    </template>
  </blank>
</template>

<script>
import section from '@/mixins/section'
import Blank from '@/sections/Blank'

export default {
  mixins: [
    section
  ],

  components: {
    Blank
  }
}
</script>

<style lang="sass">
.get-in-touch-section__icons
  +margin('md', 0, 0, 0)
</style>
