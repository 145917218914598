<template>
  <two-column bgStyle="characters" class="easy-access-section">
    <template v-slot:title><locale :path="localePath('title')" /></template>
    <template v-slot:text><locale :path="localePath('text')" /></template>
    <template v-slot:control>
      <page-button @click="scrollToNextSection">
        <locale :path="localePath('buttonText')" />
        <button-arrow />
      </page-button>
    </template>
    <template v-slot:content>
      <square-spacer />
      <game-tile-cascade class="is-panel">
        <template v-slot:topLeft>
          <game-tile
            logoSrc="/tiles/swLogo.svg"
            bgSrc="/tiles/swTile.jpg"
            name="Shockwave"
            @click.native="$store.dispatch('showPortalModal', 'shockwaveModal')"
          >
            <template v-slot:topRightFlourish>
              <app-image src="/flourishes/ghost.svg" />
            </template>
            <template v-slot:bottomLeftFlourish>
              <app-image src="/flourishes/coin.svg" />
            </template>
          </game-tile>
        </template>
        <template v-slot:center>
          <game-tile
            logoSrc="/logo.png"
            bgSrc="/tiles/agTile.jpg"
            name="Addicting Games"
            @click.native="$store.dispatch('showPortalModal', 'agModal')"
          >
            <template v-slot:topRightFlourish>
              <app-image src="/flourishes/stars.svg" />
            </template>
            <template v-slot:bottomLeftFlourish>
              <app-image src="/flourishes/phone.svg" />
            </template>
          </game-tile>
        </template>
        <template v-slot:bottomRight>
          <game-tile
            class="easy-access-section__tile__io"
            logoSrc="/tiles/ioGamesLogo.png"
            bgSrc="/tiles/ioGamesTile.jpg"
            name="ioGames.space"
            @click.native="$store.dispatch('showPortalModal', 'ioGamesModal')"
          >
            <template v-slot:topRightFlourish>
              <app-image src="/flourishes/1Up.svg" />
            </template>
            <template v-slot:bottomLeftFlourish>
              <app-image src="/flourishes/controller.svg" />
            </template>
          </game-tile>
        </template>
      </game-tile-cascade>
    </template>
    <template v-slot:contentColFlourish>
      <flourish
        shape="section2"
        :scale="1.25"
        offsetY="-10%"
        :fillSpace="true"
      />
    </template>
  </two-column>
</template>

<script>
import section from '@/mixins/section'
import TwoColumn from '@/sections/TwoColumn'

export default {
  mixins: [
    section
  ],

  components: {
    TwoColumn
  }
}
</script>

<style lang="sass">
.easy-access-section__tile__io
  .game-tile__inner
    border: solid 3px #707070
</style>
