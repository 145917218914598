<template>
  <component :is="tagName" :to="to" :href="href" :target="target" class="contact-link">
    <span class="contact-link__title">
      <slot name="title" />
    </span>
    <span class="contact-link__text">
      <slot name="text" />
    </span>
  </component>
</template>

<script>
import link from '@/mixins/link'

export default {
  mixins: [
    link
  ]
}
</script>

<style lang="sass">
.contact-link
  color: $lightFontColor
  display: block
  cursor: pointer
  letter-spacing: 1px
  text-transform: uppercase

.contact-link__text,
.contact-link__title
  display: block

.contact-link__title
  font-weight: $titleFontWeight
  font-family: $titleFont
  font-size: 150%
  line-height: 1
  +margin(0, 0, 'verySm')

.contact-link__text
  white-space: nowrap
  +isDesktop
    font-size: 80%
</style>
