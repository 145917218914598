<template>
  <div class="game-container">
    <object :data="src" />
  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="sass">
.game-container
  width: 100%
  background: #000
  border-radius: $borderRadius
  position: relative
  overflow: hidden

  object
    display: block
    width: 100%
    +margin('sm')
    +isMobile
      height: 25rem
    +isDesktop
      min-height: 30vw
</style>
