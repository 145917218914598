import { scrollToNextSection } from '@/lib/scrollTo'

export default {
  props: {
    bgStyle: {
      type: [String, undefined],
      default: undefined
    },

    localeRootPath: {
      type: String,
      default: ''
    },

    above: {
      type: Boolean,
      default: false
    },

    headingText: {
      type: String,
      default: ''
    }
  },

  methods: {
    localePath (path) {
      return [this.localeRootPath, path].join('.')
    },

    scrollToNextSection () {
      scrollToNextSection(this.$el)
    }
  }
}
