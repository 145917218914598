<template>
  <div
    :class="{
      'flourish': true,
      'is-wide': wide,
      'is-hidden-desktop': mobileOnly,
      'is-hidden-mobile': desktopOnly,
      'is-fill-space': fillSpace,
      'is-pull-top': pullTop,
      'is-pull-left': pullLeft,
      'is-pull-bottom': pullBottom,
      'is-pull-right': pullRight,
    }"
    :style="{
      margin,
      transform: `translate(${offsetX}, ${offsetY}) translateY(${parallaxY}px) scale(${scale})`
    }"
    aria-hidden="true"
  >
    <app-image
      :src="`/flourishes/${shape}.svg`"
      :style="{
        width,
        height
      }"
      alt="flourish"
    />
  </div>
</template>

<script>
import resized from '@/mixins/resized'
import scrolled from '@/mixins/scrolled'

export default {
  resized () {
    this.checkWidth()
    this.findSectionTop()
  },

  scrolled (scrollY) {
    this.scrollY = scrollY
  },

  mixins: [
    resized,
    scrolled
  ],

  props: {
    shape: {
      type: String,
      required: true
    },

    mobileOnly: {
      type: Boolean,
      default: false
    },

    desktopOnly: {
      type: Boolean,
      default: false
    },

    offsetX: {
      type: String,
      default: '0'
    },

    offsetY: {
      type: String,
      default: '0'
    },

    scale: {
      type: Number,
      default: 1
    },

    fillSpace: {
      type: Boolean,
      default: false
    },

    pullTop: {
      type: Boolean,
      default: false
    },

    pullLeft: {
      type: Boolean,
      default: false
    },

    pullBottom: {
      type: Boolean,
      default: false
    },

    pullRight: {
      type: Boolean,
      default: false
    },

    margin: {
      type: String,
      default: '0'
    },

    width: {
      type: [String, undefined],
      default: undefined
    },

    height: {
      type: [String, undefined],
      default: undefined
    }
  },

  data () {
    return {
      wide: true,
      top: 0,
      scrollY: window.scrollY,
      parentSection: undefined
    }
  },

  computed: {
    scrollFactor () {
      return -6
    },

    parallaxY () {
      return ((this.scrollY - this.top) / this.scrollFactor)
    }
  },

  mounted () {
    this.checkWidth()
    this.findSectionTop()
  },

  methods: {
    checkWidth () {
      const bRect = this.$el.getBoundingClientRect()
      this.wide = bRect.width > bRect.height
    },

    findSectionTop () {
      const parentSection = this.parentSection || this.$el.closest('.page-section')
      if (!this.parentSection) this.parentSection = parentSection

      const bRect = parentSection.getBoundingClientRect()
      this.top = bRect.top + (bRect.height / 2) + window.scrollY
    }
  }
}
</script>

<style lang="sass">
.flourish
  display: flex
  z-index: -1
  position: absolute
  pointer-events: none
  font-size: 0
  word-spacing: 0

  &.is-fill-space
    top: 0
    left: 0
    bottom: 0
    right: 0

  img
    height: auto
    width: 100%
    margin: auto
  &.is-wide
    img
      height: 100%
      width: auto

  &.is-pull-top
    top: 0
  &.is-pull-left
    left: 0
  &.is-pull-bottom
    bottom: 0
  &.is-pull-right
    right: 0
</style>
