<template>
  <blank :centerText="false" class="promo-games-section">
    <template v-slot:content>
      <page-section-container>
        <page-section-content style="position:relative">
          <div class="promo-games__panel">
            <h3>Featured Games</h3>
            <div class="promo-games__panel-inner">
              <div class="promo-games__panel-side">
                <a class="promo-games__panel-graphic" href="//venturebeat.com/2021/09/07/enthusiast-gaming-acquires-addicting-games-for-35m/" target="_blank">
                  <img src="/EGPromo.jpg" />
                </a>
                <p class="promo-games__panel-text">
                  Addicting Games Acquired<br />
                  <page-link class="is-link" href="//venturebeat.com/2021/09/07/enthusiast-gaming-acquires-addicting-games-for-35m/" target="_blank">Read News</page-link>
                </p>
              </div>
              <div class="promo-games__panel-side">
                <a class="promo-games__panel-graphic" href="//www.pocketgamer.biz/news/77217/addicting-games-acquires-2d-tank-arena-game-diepio/" target="_blank">
                  <img src="/diepPromo.jpg" />
                </a>
                <p class="promo-games__panel-text">
                  Diep.io<br />
                  <page-link class="is-link" href="//diep.io/" target="_blank">Play Now</page-link>
                </p>
              </div>
            </div>
          </div>
        </page-section-content>
      </page-section-container>
    </template>
  </blank>
</template>

<script>
import section from '@/mixins/section'
import Blank from '@/sections/Blank'

export default {
  mixins: [
    section
  ],

  components: {
    Blank
  }
}
</script>

<style lang="sass">
.promo-games-section
  position: relative
  z-index: 10
  color: $white

  .page-link
    color: $red

  + section
    padding-top: 155px
    +viewport('>=tablet')
      padding-top: 175px
    +viewport('>=desktop')
      padding-top: 200px

.promo-games__panel
  background-color: lighten($black, 10)
  box-shadow: 0 3px 6px rgba($black, 0.4)
  border-radius: $borderRadius
  +padding('md')
  position: absolute
  bottom: -100px
  width: 100%

  h3
    +margin(0, 0, 'sm')
    font-size: 150%
    font-weight: 800
    text-transform: uppercase
    font-family: 'Work Sans', sans-serif

.promo-games__panel-inner
  display: flex
  margin: -$marginSm

.promo-games__panel-side
  flex: 50%
  margin: $marginSm

.promo-games__panel-graphic
  position: relative
  display: block
  img
    width: 100%
    height: auto
    border-radius: 0.5rem
    box-shadow: 0 0 0 0rem $red
    cursor: pointer
    transition-property: all
    transition-duration: $tsxDurationQuick
    transition-timing-function: $tsxEasing
    +hover
      box-shadow: 0 0 0 0.3rem $red
      filter: contrast(110%) brightness(1.1)

.promo-games__panel-text
  line-height: 1.2
  margin-top: 10px
  font-size: 90%
  br
    margin: 4px 0 0 0 !important
</style>
