import Vue from 'vue'
import Vuex from 'vuex'
import { getLangCookie, getPreferredLang, setLangCookie } from '@/lib/lang'
import { checkBreakpoint } from '@/plugins/viewport'
import english from '@/../public/locale/en'
import websiteConfig from '@/website.config'

Vue.use(Vuex)

const defaultLang = getLangCookie() || getPreferredLang() || websiteConfig.lang.default
const defaultLocale = websiteConfig.lang.default === defaultLang ? english : {}

export default new Vuex.Store({
  state: {
    headerKind: 'static', // 'static', 'fixed', 'float'
    isMobile: true,
    isLoading: false,
    lang: defaultLang,
    locale: defaultLocale,
    isModalOpen: false,
    isHydrated: false,
    modal: undefined
  },

  mutations: {
    SET_HEADER_KIND (state, kind) {
      state.headerKind = kind
    },

    SET_MOBILE (state, isMobile) {
      state.isMobile = isMobile
    },

    SET_LOADING (state, isLoading) {
      state.isLoading = isLoading
    },

    SET_LANG (state, lang) {
      state.lang = lang
    },

    SET_LOCALE (state, locale) {
      Vue.set(state, 'locale', locale)
    },

    SET_MODAL (state, modal) {
      Vue.set(state, 'modal', modal)
    }
  },

  actions: {
    async setHeaderKind ({ commit }, kind) {
      commit('SET_HEADER_KIND', kind)
    },

    async updateViewport ({ commit }) {
      commit('SET_MOBILE', checkBreakpoint('<=tablet'))
    },

    async setLoading ({ commit }, isLoading) {
      commit('SET_LOADING', isLoading)
    },

    async setLanguage ({ commit }, lang) {
      try {
        const result = await fetch(`/locale/${lang}.json`)
        const locale = await result.json()
        setLangCookie(lang)
        commit('SET_LANG', lang)
        commit('SET_LOCALE', locale)
      } catch (err) {
        console.warn(err) // eslint-disable-line
      }
    },

    /* {
      bgColor: String,
      name: String,
      hideCloseButton: Boolean,
      meta: Object
    } */
    async showModal ({ commit }, modal) {
      commit('SET_MODAL', modal)
    },

    async showContactModal ({ commit }) {
      commit('SET_MODAL', {
        bgColor: websiteConfig.colors.black,
        name: 'contactModal'
      })
    },

    async showGamePodModal ({ commit }, gameKey) {
      commit('SET_MODAL', {
        bgColor: websiteConfig.colors.darkBlue,
        name: 'gamePodModal',
        hideCloseButton: true,
        meta: {
          gameKey
        }
      })
    },

    async showPortalModal ({ commit }, name) {
      commit('SET_MODAL', {
        bgColor: websiteConfig.colors.black,
        name
      })
    },

    async showTeamModal ({ commit }, teamMember) {
      commit('SET_MODAL', {
        bgColor: websiteConfig.colors.black,
        name: 'teamMemberModal',
        meta: {
          teamMember
        }
      })
    },

    async hideModal ({ commit }) {
      commit('SET_MODAL', undefined)
    }
  }
})
