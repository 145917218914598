<template>
  <div class="info-tile-image" :style="{ backgroundImage: `url('${imageSrc}')` }" />
</template>

<script>
export default {
  props: {
    imageSrc: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="sass">
.info-tile-image
  position: relative
  border-radius: $borderRadius
  background-color: $black
  background-position: 50% 50%
  box-shadow: 0 3px 6px rgba($black, 0.2)
  background-size: cover
  background-repeat: no-repeat
  width: 100%
  height: 100%
  min-height: 13rem
</style>
