<template>
  <div :class="{ 'modal': true, 'is-open': isModalOpen  }">
    <transition name="tsx__fade">
      <div
        v-if="isModalOpen && !modal.hideCloseButton"
        class="modal__close-button"
      >
        <close-button
          @click="$store.dispatch('hideModal')"
        />
      </div>
    </transition>
    <transition name="tsx__fade">
      <div
        v-if="isModalOpen"
        class="modal__overlay"
        :style="{ backgroundColor: modal.bgColor }"
      />
    </transition>
    <div class="modal__inner" @click.self="$store.dispatch('hideModal')">
      <div class="modal__content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { disableScroll, enableScroll } from '@/lib/scrollLock'
import videoEventBus from '@/lib/videoEventBus'

export default {
  computed: {
    ...mapState([
      'modal'
    ]),

    isModalOpen () {
      return this.$store.state.modal !== undefined
    }
  },

  watch: {
    modal (modal) {
      if (!process.browser) return

      if (modal) {
        disableScroll()
      } else {
        enableScroll()
        videoEventBus.pauseAll()
      }
    }
  }
}
</script>

<style lang="sass">
.modal
  z-index: $zLevel1
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  pointer-events: none
  transition: backdrop-filter $tsxDuration $tsxEasing
  backdrop-filter: grayscale(0%)
  overflow: hidden
  +isDesktop
    display: flex
  &.is-open
    pointer-events: auto
    backdrop-filter: grayscale(50%)
    .modal__inner
      overflow-y: auto

.modal__inner
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  z-index: 1
  +isDesktop
    display: flex

.modal__close-button
  position: absolute
  top: 0
  right: 0
  z-index: 2
  +margin('md', 'md', 0)

.modal__overlay
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  z-index: 0
  opacity: 0.65
  background-color: $black

.modal__content
  position: relative
  z-index: 1
  +isMobile
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
  +viewport('>=desktop')
    +padding('sm')
    margin: auto
    min-width: 35vw
    max-width: calc(100vw - #{$marginMdWs})
    // height: calc(100vh - #{$marginMdWs})
</style>
