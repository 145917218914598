<template>
  <section
    :class="{
      'page-section': true,
      [`is-bg-${bgStyle}`]: true,
      'is-above': above,
      'is-overflow-hidden': overflowHidden,
      'is-only-section': onlySection
    }"
  >
    <slot />
  </section>
</template>

<script>
export default {
  props: {
    bgStyle: {
      type: [String, undefined],
      default: undefined
    },

    above: {
      type: Boolean,
      default: false
    },

    overflowHidden: {
      type: Boolean,
      default: true
    },

    onlySection: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="sass">
.page-section
  display: flex
  align-items: center
  width: 100%
  position: relative
  background-position: 50% 50%
  background-size: cover
  background-repeat: no-repeat
  padding: 15rem 0
  z-index: 0
  +isDesktop
    padding: 15rem 0

  &.is-overflow-hidden
    overflow: hidden

  &.is-only-section
    padding: 6.25rem 0
    +isDesktop
      padding: 12.5rem 0

  &.is-above
    z-index: 1
    box-shadow: 0 4px 25px rgba(0,0,0,0.4)
    +isDesktop
      box-shadow: 0 5px 35px rgba(0,0,0,0.4)

  &.is-bg-hexagons
    background-color: $darkBlue
    background-image: url("/bgs/hexagons.jpg")

  &.is-bg-characters
    background-color: $darkRed
    background-image: url("/bgs/characters.jpg")

  &.is-bg-math
    background-color: $blue
    background-image: url("/bgs/math.jpg")
</style>
