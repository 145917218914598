
export default {
  data () {
    return {
      scrollHandler: undefined
    }
  },

  beforeMount () {
    if (!process.browser) return
    this.scrollHandler = this.scroll.bind(this)
    window.addEventListener('scroll', this.scrollHandler)
  },

  beforeDestroy () {
    if (!process.browser) return
    window.removeEventListener('scroll', this.scrollHandler)
  },

  methods: {
    scroll () {
      this.$options.scrolled && this.$options.scrolled.call(this, window.scrollY)
    }
  }
}
