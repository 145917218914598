<template>
  <header
    :class="{
      'page-header': true,
      [`is-kind-${kind}`]: true,
      'is-bg-visible': scrollY > 50
    }"
  >
    <page-section-container class="page-header__container">
      <div class="page-header__inner">
        <div class="page-header__logo">
          <page-link to="/" class="page-header__logo-link">
            <app-image src="/logo.png" :alt="$constants.meta.siteName" />
          </page-link>
        </div>
        <div class="page-header__links">
          <slot />
        </div>
      </div>
    </page-section-container>
  </header>
</template>

<script>
import scrolled from '@/mixins/scrolled'

export default {
  props: {
    kind: {
      type: String,
      default: 'static' // 'static', 'fixed', 'float'
    }
  },

  mixins: [
    scrolled
  ],

  data () {
    return {
      scrollY: window.scrollY
    }
  },

  scrolled (scrollY) {
    this.scrollY = scrollY
  },

  created () {
    this.$store.dispatch('setHeaderKind', this.$props.kind)
  }
}
</script>

<style lang="sass">
.page-header
  position: relative
  top: 0
  left: 0
  width: 100%
  height: $pageHeaderHeight
  transition-property: background-color, backgrop-filter
  transition-duration: $tsxDuration
  transition-timing-function: $tsxEasing
  background-color: rgba($black,0)
  backdrop-filter: grayscale(0%)
  &.is-bg-visible
    background-color: rgba($white,0.65)
    backdrop-filter: grayscale(100%)
  +isDesktop
    height: $pageHeaderHeightWs
  &.is-kind-float
    z-index: $zLevel1
    position: absolute
  &.is-kind-fixed
    z-index: $zLevel1
    position: fixed

.page-header__container
  height: 100%

.page-header__inner
  display: flex
  align-items: center
  height: 100%

.page-header__logo-link
  transition: transform $tsxDurationQuick $tsxEasing
  &:active
    transform: translateY(3px)

.page-header__logo
  font-size: 0
  word-spacing: 0
  img
    height: calc(#{$pageHeaderHeight} - #{$marginSm})
    width: auto
    +isDesktop
      height: calc(#{$pageHeaderHeightWs} - #{$marginSmWs})

.page-header__links
  margin-left: auto
  a
    display: inline-flex
    +margin(0, 0, 0, 'md')
    font-weight: 800
</style>
