<template>
  <page-section class="hero-section" :bgStyle="bgStyle">
    <page-section-container>
      <page-section-content>
        <page-section-columns>
          <template v-slot:left>
            <page-section-heading :level="1" :text="headingText" />
            <page-section-text v-if="$slots.text">
              <slot name="text" />
            </page-section-text>
            <template v-if="$slots.control">
              <br />
              <slot name="control" />
            </template>
            <slot name="textColFlourish" />
          </template>
        </page-section-columns>
      </page-section-content>
    </page-section-container>
    <slot name="sectionFlourish" />
  </page-section>
</template>

<script>
import section from '@/mixins/section'

export default {
  mixins: [
    section
  ]
}
</script>

<style lang="sass">
.hero-section
  +isMobile
    background-position: 0 50%
</style>
