<template>
  <div class="video-container" @click="playPause" aria-label="play pause toggle">
    <video ref="video" @pause="pause">
      <source :src="`${src}.mp4`" type="video/mp4">
      <source :src="`${src}.webm`" type="video/ogg">
    </video>
    <transition name="tsx__fade">
      <div v-if="!playing" class="video-container__overlay" />
    </transition>
    <transition name="tsx__pop">
      <div v-if="!playing" class="video-container__play-button__wrapper">
        <div class="video-container__play-button">
          <app-image src="/playArrow.svg" alt="Play" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import videoEventBus from '@/lib/videoEventBus'

export default {
  props: {
    src: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      playing: false
    }
  },

  created () {
    this.playHandler = (evt) => {
      if (evt.detail.src === this.src) return
      this.pause()
    }
    videoEventBus.addEventListener('play', this.playHandler)

    this.pauseAllHandler = () => this.pause()
    videoEventBus.addEventListener('pauseAll', this.pauseAllHandler)
  },

  beforeDestroy () {
    videoEventBus.removeEventListener('play', this.playHandler)
    videoEventBus.removeEventListener('pauseAll', this.pauseAllHandler)
  },

  methods: {
    playPause () {
      this.playing = !this.playing

      const { video } = this.$refs
      if (this.playing) {
        video.play()
        videoEventBus.play(this.src)
      } else {
        video.pause()
      }
    },

    pause () {
      const { video } = this.$refs
      video.pause()
      this.playing = false
    }
  }
}
</script>

<style lang="sass">
.video-container
  width: 100%
  +isDesktop
    display: flex
  background: #000
  border-radius: $borderRadius
  position: relative
  overflow: hidden
  cursor: pointer
  +hover
    .video-container__play-button
      background: lighten($red, 7)
      box-shadow: 0 0 0 0.6rem rgba($red, 0.4)

  video
    display: block
    width: 100%
    height: auto
    margin: auto
    pointer-events: none

.video-container__overlay
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  background: rgba($black, 0.5)
  backdrop-filter: grayscale(50%)
  pointer-events: none

.video-container__play-button__wrapper
  position: absolute
  z-index: 2
  top: 50%
  left: 50%
  margin: -3.25rem 0 0 -3.25rem

.video-container__play-button
  border-radius: 100rem
  border: none
  width: 6.5rem
  height: 6.5rem
  transition-properties: background, box-shadow
  transition-duration: $tsxDurationQuick
  transition-timing-function: $tsxEasing
  box-shadow: 0 0 0 0 transparent
  background: $red
  cursor: pointer
  pointer-events: none
  display: flex
  img
    margin: auto
    height: 2.25rem
    transform: translateX(0.15rem)
    width: auto
</style>
