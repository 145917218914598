export const scrollToEl = (el) => {
  const bRect = el.getBoundingClientRect()
  window.scrollTo({
    top: window.scrollY + bRect.top,
    behavior: 'smooth'
  })
}

export const scrollToNextSection = (fromEl) => {
  const sectionEls = Array.from(document.querySelectorAll('.page-section'))
  const fromIndex = sectionEls.indexOf(fromEl)
  const nextEl = sectionEls[fromIndex + 1]
  if (!nextEl) return
  scrollToEl(nextEl)
}
