<template>
  <div class="blank-section">
    <page-section-content v-if="$slots.title" class="blank-section__header">
      <page-section-heading :level="2" :color="headingColor">
        <slot name="title" />
      </page-section-heading>
      <page-section-text v-if="$slots.text">
        <slot name="text" />
      </page-section-text>
      <template v-if="$slots.control">
        <br />
        <slot name="control" />
      </template>
    </page-section-content>
    <page-section-content>
      <slot name="content" />
    </page-section-content>
  </div>
</template>

<script>
import section from '@/mixins/section'

export default {
  mixins: [
    section
  ],

  props: {
    centerText: {
      type: Boolean,
      default: true
    },

    headingColor: {
      type: String,
      default: 'black'
    }
  }
}
</script>
