<template>
  <page>
    <div class="home-page">
      <!-- Hero -->
      <simple-hero localeRootPath="pages.home.hero" class="home-page__hero" />

    </div>
  </page>
</template>

<script>
import page from '@/mixins/page'
import EasyAccess from '@/sections/premade/EasyAccess'
import GetInTouch from '@/sections/premade/GetInTouch'
import PromoGames from '@/sections/premade/PromoGames'
import OriginalGames from '@/sections/premade/OriginalGames'
import PlayToLearn from '@/sections/premade/PlayToLearn'
import SimpleHero from '@/sections/premade/SimpleHero'

export default {
  meta () {
    return {
      title: this.$locale('pages.home.pageTitle'),
      titleTemplate: '%s'
    }
  },

  mixins: [
    page
  ],

  components: {
    EasyAccess,
    GetInTouch,
    PromoGames,
    OriginalGames,
    PlayToLearn,
    SimpleHero
  },

  data () {
    return {
      visibleGame: undefined
    }
  },

  computed: {
    games () {
      const gameKeys = this.$constants.content.featuredGames
      const games = this.$locale('games')

      return gameKeys.map(key => ({
        key,
        ...games[key]
      }))
    },

    teamMembers () {
      return this.$locale('pages.about.teamMembers')
    }
  }
}
</script>

<style lang="sass">
.home-page__hero

.about-page
  .team-photo
    width: 100%
    height: auto
    border-radius: 1.5rem

.about-page__section
  background-color: #101010 !important
  background-attachment: fixed
  background-size: 100vw auto
  background-position: top center

.about-page__three-up
  +isMobile
    > div:not(:last-child)
      margin-bottom: $marginMd
  +isDesktop
    display: flex
    margin: 0 (-$marginMdWs)
    > div
      flex: 1
      margin: $marginMdWs
      margin-bottom: 0

.about-page__team-columns
  .page-section-columns__column
    width: 100%

.about-page__team
  > div
    margin-top: $marginMd
  +isDesktop
    margin: 0 (-$marginSmWs)
    display: flex
    flex-wrap: wrap
    position: relative
    > div
      display: inline-block
      margin: $marginSmWs
      // flex-basis: calc(33.33% - #{$marginSmWs * 2})
      flex-basis: calc(50% - #{$marginSmWs * 2})

.about-page__news
  +isDesktop
    position: sticky
    top: calc(#{$pageHeaderHeightWs} + #{$marginMdWs})

.about-page__news-list
  +margin('md', 0, 0)

.about-page__location-grid
  position: relative
  width: 100%
  height: 100%

.about-page__location-grid-inner
  position: absolute
  display: flex
  top: 0
  left: 0
  bottom: 0
  right: 0
  +isDesktop
    top: $marginMdWs
    left: $marginMdWs
    bottom: $marginMdWs

.about-page__location-tile
  position: relative
  width: 60%
  background: $black
  border-radius: $borderRadius
  overflow: hidden
  background-size: cover
  background-position: 50% 50%
  +tileShadow
  &.is-topleft
    position: absolute
    top: 0
    left: 0
  &.is-bottomright
    position: absolute
    bottom: 0
    right: 0

  iframe
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%

.about-page__team-pod
  width: 100%
  height: 100px
  background: red

.about-page__team-photo-caption
  color: $white
  font-size: 85%
  opacity: 0.6
  text-align: center
  br
    +margin('verySm', 0, 0)

</style>
