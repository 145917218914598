import Vue from 'vue'
import meta from 'vue-meta'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faGamepad,
  faEnvelope,
  faArrowCircleRight,
  faMobileAlt,
  faDesktop
} from '@fortawesome/free-solid-svg-icons'
import {
  faTwitter,
  faFacebook,
  faYoutube
} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import anime from '@/plugins/anime'
import constants from '@/plugins/constants'
import cookies from '@/plugins/cookies'
import loading from '@/plugins/loading'
import locale from '@/plugins/locale'
import uid from '@/plugins/uid'
import viewport from '@/plugins/viewport'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import componentsFromDir from '@/lib/componentsFromDir'

Vue.config.productionTip = false

Vue.use(anime)
Vue.use(constants)
Vue.use(cookies)
Vue.use(loading)
Vue.use(locale)
Vue.use(uid)
Vue.use(viewport)
Vue.use(meta)

library.add(faGamepad)
library.add(faEnvelope)
library.add(faArrowCircleRight)
library.add(faMobileAlt)
library.add(faDesktop)
library.add(faTwitter)
library.add(faFacebook)
library.add(faYoutube)
Vue.component('font-awesome-icon', FontAwesomeIcon)

componentsFromDir(
  require.context('./components', true, /[A-Z]\w+\.(vue)$/),
  (a, b) => Vue.component(a, b)
)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
