// call this to Disable
export function disableScroll () {
  document.body.classList.add('has-scroll-lock')
  document.querySelector('html').classList.add('has-scroll-lock')
}

// call this to Enable
export function enableScroll () {
  document.body.classList.remove('has-scroll-lock')
  document.querySelector('html').classList.remove('has-scroll-lock')
}
