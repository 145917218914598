<template>
  <div :class="{ 'game-tile-grid': true, [`is-width-${width}`]: true }">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: '2' // '2', '3'
    }
  }
}
</script>

<style lang="sass">
.game-tile-grid
  display: flex
  flex-wrap: wrap
  position: relative

  &.is-width-2
    margin: -$marginMd
    +isDesktop
      margin: -$marginMdWs
    +viewport('>=tablet', '<desktop')
      margin: -($marginMdWs/2)
    .game-tile-grid-item
      +margin('md')
      flex: 1 0 calc(50% - #{$marginMd * 2})
      +isDesktop
        flex: 1 0 calc(50% - #{$marginMdWs * 2})
      +viewport('>=tablet', '<desktop')
        margin: $marginMdWs/2
        flex: 1 0 calc(50% - #{$marginMdWs})

  &.is-width-3
    margin: -$marginSm
    +isDesktop
      margin: -$marginSmWs
    +viewport('>=tablet', '<desktop')
      margin: -($marginSmWs/2)
    .game-tile-grid-item
      +margin('sm')
      flex: 1 0 calc(33% - #{$marginSm * 2})
      +isDesktop
        flex: 1 0 calc(33% - #{$marginSmWs * 2})
      +viewport('>=tablet', '<desktop')
        margin: $marginSmWs/2
        flex: 1 0 calc(33% - #{$marginSmWs})
</style>
