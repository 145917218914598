<template>
  <modal-container name="agModal" class="addicting-games-modal">
    <page-section-container>
      <img :src="modal.logoSrc" class="modal-logo" />
      <page-section-columns :narrowSideColumn="true" :alignCenter="false" class="modal-columns">
        <template v-slot:left>
          <div class="modal-content">
            <p v-for="(p, i) in modal.description" :key="i" v-html="p">
            <p v-if="modal.linkHref">
              <page-link class="is-link" :href="modal.linkHref" target="_blank">
                Visit {{ modal.linkText }}
              </page-link>
            </p>
          </div>
        </template>
        <template v-slot:right>
          <fact-list class="modal-list">
            <li v-for="(li, i) in modal.points" :key="i" v-html="li" />
          </fact-list>
        </template>
      </page-section-columns>
    </page-section-container>
  </modal-container>
</template>

<script>
export default {
  computed: {
    modal () {
      return this.$local('pages.home.easyAccess.modals.agModal')
    }
  }
}
</script>

<style lang="sass">
.addicting-games-modal
  color: $white
  background: $black
  background-image: url('/bgs/chalkboard.jpg')
  background-size: cover
  background-position: 0 0
  overflow-x: hidden
  overflow-y: auto
  +isMobile
    +padding('md', 0)
  +isDesktop
    +tileShadow
    +padding('lg', 0)
    border-radius: $borderRadiusOuter
    max-width: 950px

  .page-link
    color: $primaryColor

  .modal-logo
    width: auto
    height: 7.5rem
    +margin(0, 0, 'sm')

  .modal-content
    p
      +margin(0, 0, 'md')
      &:last-child
        margin-bottom: 0

  .modal-list
    font-size: 80%

  .modal-columns
    +isDesktop
      .page-section-columns__column
        &:last-child
          margin-left: 0
</style>
