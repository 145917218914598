<template>
  <div id="app">
    <nav>
      <page-header kind="fixed">
        <!-- Main navigation links -->
        <nav-link
          v-for="(link, i) in $locale('links.header')"
          :key="i"
          :to="link.to"
          :href="link.href"
          :target="link.target"
        >
          <locale :path="`links.header.${i}.text`" />
        </nav-link>
      </page-header>
    </nav>
    <main :class="{ [`has-header-${$store.state.headerKind}`]: true }">
      <!-- <transition name="tsx__fade" mode="out-in"> -->
        <router-view />
      <!-- </transition> -->
    </main>
    <page-footer>
      <!-- Footer links -->
      <footer-link
        v-for="(link, i) in $locale('links.footer')"
        :key="i"
        :to="link.to"
        :href="link.href"
        :target="link.target"
      >
        <locale :path="`links.footer.${i}.text`" />
      </footer-link>
    </page-footer>
    <modal></modal>
    <page-loader>
      <span />
    </page-loader>
  </div>
</template>

<script>
import resized from '@/mixins/resized'

export default {
  resized () {
  },

  mixins: [
    resized
  ],

  async created () {
    this.resize(0)
  }
}
</script>

<style lang="sass">
@import "styles/reset.sass"
@import "styles/app.sass"
@import "styles/transitions.sass"
</style>
