import store from '@/store'
import getItem from 'lodash/get'

export function locale (path) {
  return getItem(store.state.locale, path)
}

function install (Vue) {
  Vue.prototype.$locale = locale
}

export default { install }
