<template>
  <transition name="tsx__pop">
    <div v-show="isOpen" class="modal-container">
      <slot />
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true
    }
  },

  computed: {
    isOpen () {
      return this.$store.state.modal && (this.$store.state.modal.name === this.name)
    }
  }
}
</script>

<style lang="sass">
.modal-container
  width: 100%
  display: flex
  position: relative
  flex-direction: column
  pointer-events: auto
  +viewport('>=desktop')
    height: 100%
</style>
