<template>
  <modal-container name="teamMemberModal" class="team-member-modal">
    <page-section-container class="team-member-modal__container">
      <div
        v-for="(member, key) in teamMembers"
        v-show="lastTeamMember === key"
        :key="key"
        class="team-member-modal__inner"
      >
        <div class="team-member-modal__profile">
          <div class="team-member-modal__photo" :style="{ backgroundImage: `url('/team/${key}.jpg')` }" />
          <div>
            <p>{{ member.name }}</p>
            <p>{{ member.title }}</p>
            <ul v-if="member.links && member.links.length">
              <li
                v-for="link in member.links"
                :key="link"
              >
                <page-link :href="link" target="_blank">{{ formatLink(link) }}</page-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="team-member-modal__bio">
          <p v-html="member.bio" />
        </div>
      </div>
    </page-section-container>
  </modal-container>
</template>

<script>
export default {
  data () {
    return {
      lastTeamMember: undefined
    }
  },

  computed: {
    teamMembers () {
      return this.$locale('pages.about.teamMembers')
    },

    visibleTeamMember () {
      return this.$store.state.modal &&
        this.$store.state.modal.meta &&
        this.$store.state.modal.meta.teamMember
    }
  },

  methods: {
    formatLink (link) {
      return link
        .replace('https://', '')
        .replace('http://', '')
        .replace('//', '')
    }
  },

  watch: {
    visibleTeamMember (nextTeamMember) {
      if (nextTeamMember !== undefined) this.lastTeamMember = nextTeamMember
    }
  }
}
</script>

<style lang="sass">
.team-member-modal
  color: $white
  background: $black
  overflow-x: hidden
  overflow-y: auto
  +isMobile
    +padding('md', 0)
    display: flex
    min-height: 100vh
  +isDesktop
    +tileShadow
    +padding('lg', 0)
    border-radius: $borderRadiusOuter
    max-width: 950px

.team-member-modal__inner
  +isDesktop
    display: flex

.team-member-modal__profile
  +isMobile
    display: flex
    align-items: center
    +margin(0, 0, 'md')
  +isDesktop
    +margin(0, 'md', 0)
  p
    &:first-of-type
      font-size: 120%
    &:last-of-type
      color: $primaryColor
  ul
    +margin('sm', 0, 0)
    a
      color: $white
      opacity: 0.5
      transition: opacity $tsxDurationQuick $tsxEasing
      +hover
        opacity: 1

.team-member-modal__photo
  width: 8rem
  height: 8rem
  background: #ccc
  border-radius: 1000px
  border: solid 6px $red
  background-repeat: no-repeat
  background-size: cover
  background-position: 50% 50%
  +isMobile
    +margin(0, 'md', 0)
  +isDesktop
    +margin(0, 0, 'md')
    width: 10rem
    height: 10rem

.team-member-modal__bio
  flex: 1
  +viewport('>=desktop')
    font-size: 120%
  a
    color: $white
    border-bottom: solid 2px
  p
    +margin(0, 0, 'sm')

.team-member-modal__container
  +isMobile
    margin: auto
</style>
