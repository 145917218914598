import { render, staticRenderFns } from "./ButtonArrow.vue?vue&type=template&id=17b2d013&"
var script = {}
import style0 from "./ButtonArrow.vue?vue&type=style&index=0&id=17b2d013&prod&lang=sass&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports