<template>
  <footer :class="{ 'page-footer': true, 'has-transparent-bg': transparentBg }">
    <div class="page-footer__inner">
      <div class="page-footer__item is-order-0">
      </div>
      <div class="page-footer__item is-order-2" style="white-space:nowrap">
        <div>
          <p>
            Copyright © {{ currentYear }} Supercalifragilisticexpialidocious, Inc.
          </p>
          <p>
            Suite 200, 15332 Antioch Street
          </p>
          <p>
            Los Angeles, California 90272, USA
          </p>
        </div>
      </div>
      <div class="page-footer__item is-flex is-hidden-mobile">
        <icon icon="fas-envelope" class="page-footer__icon" />
        <div class="page-footer__item">
          <div>
            <p>
              <page-link mailTo="mailto:info@supercalifragilisticexpialidocious.com" class="page-footer__link">
                <safe-email email="info@supercalifragilisticexpialidocious.com" />
              </page-link>
            </p>
          </div>
        </div>
      </div>
      <nav class="page-footer__links is-order-1">
        <slot />
      </nav>
    </div>
  </footer>
</template>

<script>
export default {
  props: {
    transparentBg: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    currentYear () {
      return new Date().getFullYear()
    }
  }
}
</script>

<style lang="sass">
.page-footer
  +padding('md')
  background: $red
  color: rgba($white, 0.6)
  line-height: 1.6
  font-size: 80%
  position: absolute
  bottom: 0
  width: 100%
  &.has-transparent-bg
    background: transparent !important

.page-footer__inner
  display: flex
  align-items: center
  +isMobile
    flex-direction: column
    align-items: start
  +isDesktop
    flex-wrap: wrap
    align-items: center
.page-footer__logo
  height: 3rem
  width: auto

.page-footer__icon
  height: 2.5rem
  font-size: 2.5rem
  width: auto
  float: left
  color: $white

.page-footer__item
  +margin(0, 0, 0, 'md')
  > .page-footer__item
    +margin(0, 0, 0, 'sm')
  display: flex
  align-items: center
  +isDesktop
    &:first-child
      margin-left: 0
    &:last-of-type:not(:first-of-type)
      margin-left: auto
    &.is-order-2
      +margin(0, 0, 0, 'sm')
  +isMobile
    +margin(0, 0, 'sm', 0)
    &.is-order-0
      order: 0
    &.is-order-1
      order: 1
    &.is-order-2
      order: 2
      margin-bottom: 0

.page-footer__link
  color: $white
  transition: color $tsxDurationQuick $tsxEasing
  +hover
    color: rgba($white, 0.6)

.page-footer__links
  +isMobile
    +margin('sm', 0)
  +viewport('>=tablet', '<highdef')
    margin: $marginSmWs auto 0 auto !important
    flex: 1 0 100%
    text-align: center
  +viewport('>=highdef')
    +margin(0, 0, 0, 'md')
  a
    +padding(0, 'verySm', 0)
    &:last-child
      padding-right: 0
    &:not(:first-child)
      &::before
        +padding(0, 'verySm', 0)
</style>
