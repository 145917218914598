<template>
  <div class="news-list">
    <transition-group tag="div" name="tsx__fade" class="news-list__inner">
      <article
        v-for="news in newsItems"
        :key="news.id"
        class="news-list__item"
      >
        <page-link :href="news.href" target="_blank">
          <div v-if="news.image" class="news-list__item__image" :style="{ backgroundImage: `url(${news.image})` }" />
          <div class="news-list__item__info">
            <h4>{{ news.title }}</h4>
            <time>{{ formatTime(news.when) }}</time>
          </div>
        </page-link>
      </article>
    </transition-group>
    <br>
    <div>
      <page-button @click.native="page++" bgColor="red" :disabled="endOfList">
        Show More
      </page-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    limit: {
      type: Number,
      default: 6
    }
  },

  data () {
    return {
      page: 0
    }
  },

  computed: {
    newsItems () {
      return this
        .$locale('pages.about.newsItems')
        .map((item, i) => {
          item.id = i
          return item
        })
        .slice(0, (this.page * this.limit) + this.limit)
    },

    endOfList () {
      const length = this.$locale('pages.about.newsItems').length
      const position = (this.page * this.limit) + this.limit
      return position >= length
    }
  },

  methods: {
    formatTime (date) {
      return date
    }
  }
}
</script>

<style lang="sass">
.news-list__inner
  width: 100%
  +isDesktop
    display: flex
    flex-wrap: wrap

.news-list__item
  +margin(0, 0, 'md')
  width: 100%
  +isDesktop
    width: calc(50% - #{$marginMdWs})
    &:nth-child(2n-1)
      margin-right: $marginMdWs
  a
    display: flex
    width: 100%
    justify-content: flex-start
    align-items: center

.news-list__item__image
  min-width: 6rem
  min-height: 5.5rem
  max-width: 6rem
  max-height: 5.5rem
  +margin(0, 'md', 0)
  +isDesktop
    min-width: 10vw
    min-height: 7vw
    max-width: 10vw
    max-height: 7vw
  background-size: cover
  background-repeat: no-repeat
  background-position: 50% 50%
  border-radius: $borderRadiusSm
  +tileShadow

.news-list__item__info
  display: flex
  justify-content: center
  flex-direction: column
  color: $white
  font-size: 120%

  time
    color: $red
    font-size: 80%
    display: block
    +margin('verySm', 0, 0)
</style>
