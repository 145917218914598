<template>
 <two-column bgStyle="math" class="play-to-learn-section" :above="true">
    <template v-slot:title>
      <div class="play-to-learn-section__teach-me-logo">
        <app-image src="/teachMeLogo.svg" alt="TeachMe Inc." />
      </div>
      <br />
      <locale :path="localePath('title')" />
      </template>
    <template v-slot:text><locale :path="localePath('text')" /></template>
    <template v-slot:control>
      <page-button @click="scrollToNextSection">
        <locale :path="localePath('buttonText')" />
        <button-arrow />
      </page-button>
    </template>
    <template v-slot:content>
      <game-tile-grid width="2">
        <game-tile-grid-item>
          <game-tile logoSrc="/tiles/typeracerLogo.png" bgSrc="/tiles/typeracerTile.jpg" @click.native="$store.dispatch('showPortalModal', 'typeracerModal')" name="TypeRacer" />
        </game-tile-grid-item>
        <game-tile-grid-item>
          <game-tile logoSrc="/tiles/mathGamesLogo.png" bgSrc="/tiles/mathGamesTile.jpg" @click.native="$store.dispatch('showPortalModal', 'mathgamesModal')" name="MathGames" />
        </game-tile-grid-item>
        <game-tile-grid-item>
          <game-tile logoSrc="/tiles/edShelfLogo.png" bgSrc="/tiles/edShelfTile.jpg" @click.native="$store.dispatch('showPortalModal', 'edshelfModal')" name="EdShelf" />
        </game-tile-grid-item>
        <game-tile-grid-item>
          <game-tile logoSrc="/tiles/playGeographyLogo.png" bgSrc="/tiles/playGeographyTile.jpg" @click.native="$store.dispatch('showPortalModal', 'playgeoModal')" name="Play Geography" />
        </game-tile-grid-item>
      </game-tile-grid>
    </template>
    <template v-slot:contentColFlourish>
      <flourish
        shape="section3"
        :scale="1.5"
        offsetX="10%"
        offsetY="5%"
        :fillSpace="true"
        :desktopOnly="true"
      />
    </template>
    <template v-slot:sectionFlourish>
      <flourish
        shape="section3Stars"
        :scale="0.75"
        offsetX="-25%"
        offsetY="30%"
        :desktopOnly="true"
        :pullBottom="true"
        :pullLeft="true"
        width="35vw"
      />
      <flourish
        shape="section3Mobile"
        :scale="1.1"
        offsetX="10%"
        :mobileOnly="true"
        :fillSpace="true"
      />
    </template>
  </two-column>
</template>

<script>
import section from '@/mixins/section'
import TwoColumn from '@/sections/TwoColumn'

export default {
  mixins: [
    section
  ],

  components: {
    TwoColumn
  }
}
</script>

<style lang="sass">
.play-to-learn-section__teach-me-logo
  +padding('verySm', 'sm')
  +margin(0, 0, 'sm')
  border-radius: $borderRadius
  background: $white
  display: inline-block
  img
    height: 3rem
    width: auto
</style>
