<template>
  <hero bgStyle="" class="simple-hero-section" style="padding-bottom:3.5rem" :headingText="$locale(localePath('title'))">
    <template v-slot:text>
      <locale :path="localePath('text')" />
    </template>
    <template v-if="$locale(localePath('buttonText'))" v-slot:control>
      <page-button bgColor="red" @click="scrollToNextSection">
        <locale :path="localePath('buttonText')" />
        <button-arrow />
      </page-button>
    </template>
  </hero>
</template>

<script>
import section from '@/mixins/section'
import Hero from '@/sections/Hero'

export default {
  mixins: [
    section
  ],

  components: {
    Hero
  }
}
</script>

<style lang="sass">
.simple-hero-section
  +isMobile
    min-height: 500px
    .page-section-container
      margin-top: -75px !important
  +viewport('<=phone')
    min-height: 550px
    .page-section-container
      margin-top: -25px !important
  +isDesktop
    min-height: 585px
    .page-section-container
      margin-top: -240px
  +viewport('<desktop')
    .page-section-container
      margin-top: -200px
  +viewport('>=highdef')
    min-height: 735px
    .page-section-container
      margin-top: -240px

.mope-promo
  width: 100%
  max-width: 605px
  display: block
</style>
