import { render, staticRenderFns } from "./FactsModal.vue?vue&type=template&id=097e3b44&"
import script from "./FactsModal.vue?vue&type=script&lang=js&"
export * from "./FactsModal.vue?vue&type=script&lang=js&"
import style0 from "./FactsModal.vue?vue&type=style&index=0&id=097e3b44&prod&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports