import Home from '@/pages/Home'

export default [
  {
    path: '/',
    name: 'home',
    component: Home
  },

  {
    path: '/not-found',
    name: 'notFound',
    component: () => import(/* webpackChunkName: "notFound" */ '@/pages/NotFound.vue')
  },

  {
    path: '*',
    name: 'fourOhFour',
    component: () => import(/* webpackChunkName: "notFound" */ '@/pages/NotFound.vue')
  }
]
