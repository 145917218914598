<template>
  <modal-container :name="name" :class="{ 'facts-modal': true, [`is-bg-${bgStyle}`]: true }">
    <page-section-container>
      <page-section-columns :narrowSideColumn="true" :alignCenter="false" class="modal-columns">
        <template v-slot:left>
          <div class="modal-content">
            <img :src="modal.logoSrc" class="modal-logo is-hidden-desktop" :style="logoHeight && { height: logoHeight }" :data-logo="name" />
            <page-link :href="modal.linkHref" target="_blank" style="display:block !important">
              <img v-if="modal.imageSrc" :src="modal.imageSrc" class="modal-image" />
            </page-link>
            <slot />
            <p v-for="(p, i) in modal.description" :key="i" v-html="p">
            <p v-if="modal.linkHref">
              <page-link class="is-link" :href="modal.linkHref" target="_blank">
                {{ modal.linkText }}
              </page-link>
            </p>
          </div>
        </template>
        <template v-slot:right>
          <aside class="modal-facts">
            <div>
              <img :src="modal.logoSrc" class="modal-logo is-hidden-mobile" :style="logoHeight && { height: logoHeight }" :data-logo="name" />
              <fact-list class="modal-list">
                <li v-for="(li, i) in modal.points" :key="i" v-html="li" />
              </fact-list>
            </div>
          </aside>
        </template>
      </page-section-columns>
    </page-section-container>
  </modal-container>
</template>

<script>
export default {
  props: {
    logoHeight: {
      type: String,
      default: ''
    },

    name: {
      type: String,
      required: true
    },

    bgStyle: {
      type: String
    },

    localePath: {
      type: String,
      required: true
    }
  },

  computed: {
    modal () {
      return this.$locale(this.localePath)
    }
  }
}
</script>

<style lang="sass">
.facts-modal
  background-size: cover
  background-position: 50% 50%
  +isMobile
    +padding('md', 0)
  +viewport('>=tablet')
    +padding('md', 0)
    min-height: 100%
  +viewport('>=desktop')
    +tileShadow
    +padding('lg', 0)
    border-radius: $borderRadiusOuter
    max-width: 950px
    min-height: none

  &.is-bg-chalkboard
    color: $white
    background-color: $black
    background-image: url('/bgs/chalkboard.jpg')
    .modal-image,
    .modal-video
      border: solid 4px $red
    .page-link
      color: $primaryColor

  &.is-bg-learn
    color: $white
    background-color: $blue
    background-image: url('/bgs/math.jpg')
    .modal-image,
    .modal-video
      border: solid 4px $white
    .page-link
      color: $white
    .is-link
      +isDesktop
        &::before
          background: $white

  &.is-bg-math
    color: $darkBlue
    background-color: $white
    background-image: url('/tiles/mathGamesTile.jpg')
    background-size: auto
    background-repeat: repeat
    .modal-image,
    .modal-video
      border: solid 4px $darkBlue
    .page-link
      color: $darkBlue
    .is-link
      +isDesktop
        &::before
          background: $darkBlue
    .fact-list li::after
      border-bottom-color: rgba($darkBlue, 0.2)

  .modal-logo
    width: 75%
    height: auto
    +margin(0, 0, 'md')
    &[data-logo="typeracerModal"]
      width: 50%

  .modal-content
    p
      +margin(0, 0, 'md')
      &:last-child
        margin-bottom: 0

  .modal-list
    font-size: 80%

  .modal-columns
    margin: 0
    +isDesktop
      .page-section-columns__column
        margin-top: 0
        margin-bottom: 0
        &:last-child
          margin-left: 0

  .modal-video
    width: 100%
    +margin(0, 0, 'md')

  .modal-image
    width: 100%
    height: auto
    +margin(0, 0, 'md')
    border-radius: $borderRadiusSm
    overflow: hidden
    box-shadow: 0 3px 15px rgba($black, 0.2)

  .modal-facts
    height: 100%
    display: flex
    align-items: center
    position: relative
</style>
