<template>
  <modal-container name="gamePodModal" class="game-pod-modal">
    <div class="game-pod-modal__overlay" @click="$store.dispatch('hideModal')"></div>
    <div
      v-for="(game, i) in games"
      :key="i"
      class="game-pod-modal__wrapper"
    >
      <page-section-container>
        <game-pod
          v-show="lastGameKey === game.key"
          :gameIconSrc="game.hasLogo ? `/games/${game.key}Logo.png` : `/games/${game.key}Bg.jpg`"
          :gameIconKind="game.hasLogo ? 'logo' : 'tile'"
          :videoType="typeof game.hasVideo === 'string' ? 'youTube': 'local'"
          :videoSrc="game.hasVideo ? (typeof game.hasVideo === 'string' ? game.hasVideo : `/games/${game.key}Video`) : undefined"
          :graphicSrc="!game.hasVideo ? `/games/${game.key}Promo.jpg` : undefined"
          :descriptionItems="game.description"
          :tags="game.tags"
          :platforms="game.platforms"
          :visible="lastGameKey === game.key"
          :hasCloseButton="true"
          @close="$store.dispatch('hideModal')"
        >
          <template v-slot:title>
            <locale :path="`games.${game.key}.name`" />
          </template>
          <template v-slot:control>
            <page-link v-if="game.gameUrl" :href="game.gameUrl" target="_blank" class="is-link">
              Play {{ game.name }}
            </page-link>
          </template>
        </game-pod>
      </page-section-container>
    </div>
  </modal-container>
</template>

<script>
export default {
  data () {
    return {
      lastGameKey: undefined
    }
  },

  computed: {
    games () {
      const gameKeys = this.$constants.content.featuredGames
      const games = this.$locale('games')

      return gameKeys.map(key => ({
        key,
        ...games[key]
      }))
    },

    visibleGameKey () {
      return this.$store.state.modal &&
        this.$store.state.modal.meta &&
        this.$store.state.modal.meta.gameKey
    }
  },

  watch: {
    visibleGameKey (nextGameKey) {
      if (nextGameKey !== undefined) this.lastGameKey = nextGameKey
    }
  }
}
</script>

<style lang="sass">
.game-pod-modal
  +isMobile
    height: 100%

.game-pod-modal__overlay
  position: absolute
  top: 0
  left: 0
  width: 100vw
  height: 100vh

.game-pod-modal__wrapper
  position: relative
  z-index: 1
  pointer-events: none
  +isMobile
    display: flex
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    .page-section-container
      margin: auto
</style>
