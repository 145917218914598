<template>
  <component
    :is="tagName"
    :to="to"
    :href="href"
    :target="target"
    :type="type"
    :class="{
      'page-button': true,
      [`is-bg-${bgColor}`]: true,
      'is-disabled': disabled
    }"
    @click="$emit('click')"
  >
    <slot />
  </component>
</template>

<script>
import link from '@/mixins/link'

export default {
  mixins: [
    link
  ],

  props: {
    bgColor: {
      type: String,
      default: 'black'
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    tagName () {
      return this.href
        ? 'a'
        : this.to
          ? 'router-link'
          : 'button'
    },

    type () {
      return (!this.to && !this.href)
        ? 'button'
        : undefined
    }
  }
}
</script>

<style lang="sass">
.page-button
  border-radius: 10rem
  border: none
  font-weight: 800
  height: 2.75rem
  font-size: 110%
  display: flex
  align-items: center
  +padding(0, 'md')
  color: $lightFontColor
  transition-properties: background, box-shadow, transform
  transition-duration: $tsxDurationQuick
  transition-timing-function: $tsxEasing
  box-shadow: 0 0 0 0 transparent
  cursor: pointer
  outline: none !important
  transform: translateY(0)
  &.is-bg-black
    background: $black
    +hover
      background: lighten($black, 10)
      box-shadow: 0 0 0 0.4rem rgba($black, 0.4)
  &.is-bg-red
    background: $red
    +hover
      background: lighten($red, 7)
      box-shadow: 0 0 0 0.4rem rgba($red, 0.4)
  &:active
    transform: translateY(4px)

  .button-arrow
    margin-left: 0.65em
    transition: margin $tsxDurationQuick $tsxEasing
  +hover
    .button-arrow
      margin-left: 1rem

  &.is-disabled
    pointer-events: none
    background: transparent !important
    box-shadow: 0 0 0 0.2rem $white
    color: $white
    opacity: 0.2
</style>
