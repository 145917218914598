<template>
  <div class="game-pod">
    <transition name="tsx__pop">
      <div class="game-pod__inner">
        <close-button v-if="hasCloseButton" @click="closePod" class="game-pod__close-button" />
        <div class="game-pod__content">
          <template v-if="visible">
            <app-image v-if="graphicSrc" class="game-pod__graphic" :src="graphicSrc" />
            <video-container v-else-if="videoSrc && videoType === 'local'" :src="videoSrc" />
            <you-tube-container v-else-if="videoSrc && videoType === 'youTube'" :src="videoSrc" />
            <game-container v-else-if="gameSrc" :src="gameSrc" />
          </template>
        </div>
        <div class="game-pod__description">
          <div>
            <page-section-heading :level="3" class="game-pod__title">
              <!-- <app-image
                :class="{
                  'game-pod__logo': true,
                  [`is-kind-${gameIconKind}`]: true
                }"
                :src="gameIconSrc"
              /> -->
              <slot name="title" />
            </page-section-heading>
            <div v-if="platforms || tags" class="game-pod__tags-platforms">
              <template v-if="platforms">
                <icon v-if="platforms.desktop" class="game-pod__icon" icon="fas-desktop" />
                <icon v-if="platforms.mobile" class="game-pod__icon" icon="fas-mobile-alt" />
              </template>
              <template v-if="tags">
                <span v-for="(item, i) in tags" :key="i" class="game-pod__tag">
                  {{ item }}
                </span>
              </template>
            </div>
            <fact-list class="fact-list">
              <li v-for="(item, i) in descriptionItems" :key="i">{{ item }}</li>
            </fact-list>
            <p>
              <slot name="control" />
            </p>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    gameIconSrc: {
      type: String,
      required: true
    },

    gameIconKind: {
      type: String,
      default: 'tile' // 'tile', 'logo'
    },

    graphicSrc: {
      type: [String, undefined],
      default: undefined
    },

    videoType: {
      type: String,
      default: 'local' // 'local', 'youTube'
    },

    videoSrc: {
      type: [String, undefined],
      default: undefined
    },

    gameSrc: {
      type: [String, undefined],
      default: undefined
    },

    descriptionItems: {
      type: Array,
      required: true
    },

    tags: {
      type: [Array, undefined],
      default: undefined
    },

    platforms: {
      type: [Object, undefined],
      default: undefined
    },

    visible: {
      type: Boolean,
      default: true
    },

    hasCloseButton: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      showMedia: false
    }
  },

  methods: {
    closePod () {
      this.$emit('close')
    }
  },

  watch: {
    visible (isVisible) {
      if (isVisible) this.showMedia = true
    }
  }
}
</script>

<style lang="sass">
.game-pod
  width: 100%
  pointer-events: none

  .fact-list
    +margin(0, 0, 'md')

.game-pod__inner
  width: 100%
  display: flex
  pointer-events: all
  color: $white
  background: $black
  border-radius: $borderRadiusOuter
  position: relative
  +padding('md')
  +tileShadow
  .page-link
    color: $primaryColor
  +isMobile
    flex-direction: column
  +isDesktop
    align-items: center

.game-pod__content
  font-size: 0
  +isMobile
    +margin(0, 0, 'md')
  +isDesktop
    flex: 1 0 66%

.game-pod__graphic
  width: 100%
  height: auto
  border-radius: $borderRadius

.game-pod__description
  +isDesktop
    display: flex
    align-items: center
    flex: 0 1 33%
    +margin(0, 0, 0, 'md')

.game-pod__logo
  height: auto
  +margin('verySm', 'sm', 'verySm')
  &.is-kind-logo
    width: 7vw
    min-width: 5rem
  &.is-kind-tile
    width: 4vw
    min-width: 3rem

.game-pod__title
  display: flex
  align-items: center
  +isDesktop
  padding-right: $marginMdWs

.game-pod__tags-platforms
  display: flex
  align-items: center
  +margin(0, 0, 'md')
  margin-top: -0.5rem !important
  +isDesktop
    +margin(0, 0, 'sm')
    margin-top: -0.5rem !important

.game-pod__tag,
.game-pod__icon
  +margin('verySm')

.game-pod__icon
  color: $white
  opacity: 0.45

.game-pod__tag
  border-radius: 10rem
  background: lighten($black, 15)
  padding: 0.1rem 0.5rem
  font-size: 70%

.game-pod__close-button
  position: absolute
  top: 0
  right: 0
  z-index: 1
  +margin('sm')
  transform: scale(0.75)
</style>
