<template>
  <page-section class="two-column-section" :bgStyle="bgStyle" :above="above">
    <page-section-container>
      <page-section-content>
        <page-section-columns :rightFirst="rightFirst">
          <template v-slot:left>
            <page-section-heading :level="2" :color="headingColor">
              <slot name="title" />
            </page-section-heading>
            <page-section-text v-if="$slots.text">
              <slot name="text" />
            </page-section-text>
            <template v-if="$slots.control">
              <br />
              <slot name="control" />
            </template>
            <slot name="textColFlourish" />
          </template>
          <template v-slot:right>
            <slot name="content" />
            <slot name="contentColFlourish" />
          </template>
        </page-section-columns>
      </page-section-content>
    </page-section-container>
    <slot name="sectionFlourish" />
  </page-section>
</template>

<script>
import section from '@/mixins/section'

export default {
  mixins: [
    section
  ],

  props: {
    rightFirst: {
      type: Boolean,
      default: false
    },

    headingColor: {
      type: String,
      default: 'black'
    }
  }
}
</script>
