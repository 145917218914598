export default {
  props: {
    to: {
      type: [String, Object, undefined],
      default: undefined
    },

    href: {
      type: [String, undefined],
      default: undefined
    },

    target: {
      type: [String, undefined],
      default: ''
    },

    mailTo: {
      type: [String, undefined]
    }
  },

  computed: {
    tagName () {
      return this.mailTo
        ? 'span'
        : this.to
          ? 'router-link'
          : 'a'
    }
  }
}
