<template>
  <component
    :is="tagName"
    :to="to"
    :href="href"
    :target="target"
    :class="{
      'game-tile': true,
      'has-panel': $slots.panel,
      'hover': true
    }"
  >
    <square-spacer />
    <div
      :class="{
        'game-tile__inner': true,
        'is-tile-bg': $slots.panel
      }"
      :style="{
        backgroundImage: `url('${bgSrc}')`
      }"
    >
      <app-image v-if="logoSrc" class="game-tile__logo" :src="logoSrc" :alt="name" />
      <div v-if="$slots.panel" class="game-tile__panel">
        <slot name="panel" />
      </div>
    </div>
    <div v-if="$slots.topRightFlourish" class="game-tile__flourish is-top-right" aria-hidden="true">
      <slot name="topRightFlourish" />
    </div>
    <div v-if="$slots.bottomLeftFlourish" class="game-tile__flourish is-bottom-left" aria-hidden="true">
      <slot name="bottomLeftFlourish" />
    </div>
  </component>
</template>

<script>
import link from '@/mixins/link'

export default {
  mixins: [
    link
  ],

  props: {
    logoSrc: {
      type: [String, undefined],
      default: undefined
    },

    bgSrc: {
      type: String,
      required: true
    },

    name: {
      type: String,
      required: true
    }
  },

  computed: {
    tagName () {
      return this.href
        ? 'a'
        : this.to
          ? 'router-link'
          : 'div'
    }
  }
}
</script>

<style lang="sass">
.game-tile
  display: block
  position: relative
  transition: transform $tsxDurationQuick $tsxEasing
  cursor: pointer

.game-tile:not(.has-panel)
  +hover
    transform: scale(1.1)
    .game-tile__inner
      // background-size: 101% 101%
      // &::after
        // opacity: 0.5
    .game-tile__logo
      transform: translate(-50%, -50%) scale(1.1)
  // &:active
  //   transform: scale(0.9)

.game-tile.has-panel
  +isMobile
    // &:active
    //   transform: scale(0.9)
    //   .game-tile__inner
    //     // background-size: 125% 125%
    //   .game-tile__logo
    //     transform: translate(-50%, 33.4%) scale(1)

.game-tile__inner
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  z-index: 0
  border-radius: $borderRadius
  background-color: $black
  background-position: 50% 50%
  +tileShadow
  transition: background-size $tsxDurationQuick $tsxEasing
  &:not(.is-tile-bg)
    // background-size: 125% 125%
    background-size: cover
    background-repeat: no-repeat

.game-tile:not(.is-panel)
  .game-tile__inner
    // &::after
    //   content: ''
    //   display: block
    //   position: absolute
    //   z-index: -1
    //   top: 0
    //   left: 0
    //   width: 100%
    //   height: 100%
    //   background: #fff
    //   border-radius: $borderRadius
    //   mix-blend-mode: overlay
    //   opacity: 0
    //   transition: opacity $tsxDuration $tsxEasing

.game-tile__logo
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  width: 120%
  height: auto
  transition: transform $tsxDurationQuick $tsxEasing

.game-tile__flourish
  position: absolute
  height: 5rem
  +isDesktop
    height: 8vw
  display: flex
  pointer-events: none
  +isDesktop
    opacity: 0
    transition: all $tsxDuration $tsxEasing
  img
    height: 100%
    width: auto
    margin: auto
  &.is-top-right
    z-index: 1
    top: 0
    right: 0
    transform: translate(40%, -40%)
    +isDesktop
      transform: translate(25%, -25%) scale(0.5)
  &.is-bottom-left
    z-index: -1
    bottom: 0
    left: 0
    transform: translate(-40%, 40%)
    +isDesktop
      transform: translate(-25%, 25%) scale(0.5)

.game-tile
  +hover
    .game-tile__flourish
      opacity: 1
      &.is-top-right
        transform: translate(50%, -50%) scale(1)
      &.is-bottom-left
        transform: translate(-50%, 50%) scale(1)

.game-tile.has-panel
  z-index: 1
  .game-tile__inner
    right: 0
    left: auto
    width: auto
    height: auto
    min-height: 100%
    min-width: 100%
  .game-tile__logo
    position: relative
    transform: translate(-50%, 33.4%)
  .game-tile__inner,
  .game-tile__logo
    transition: all $tsxDuration $tsxEasing
  +hover
    z-index: 2
    .game-tile__inner
      position: absolute
    .game-tile__logo
      width: 10rem
      +padding('sm', 0, 0)
      transform: translate(-50%, 0)
    .game-tile__panel
      width: 560px
      height: 315px
      transform: scale(1)
      opacity: 1
      display: block
      +margin('verySm', 'sm', 'sm', 'sm')
      pointer-events: auto

.game-tile__panel
  width: 0
  height: 0
  transition: all $tsxDuration $tsxEasing
  transform-origin: 100% 0%
  transform: scale(0)
  margin: 0
  opacity: 0
  pointer-events: none
</style>
