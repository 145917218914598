<template>
  <div
    :class="{
      'page-section-columns': true,
      'is-right-col-first': rightFirst,
      'is-narrow-aside': narrowSideColumn,
      'is-align-center': alignCenter
    }"
  >
    <div class="page-section-columns__column">
      <slot name="left" />
    </div>
    <div class="page-section-columns__column" v-if="$slots.right">
      <slot name="right" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    rightFirst: {
      type: Boolean,
      default: false
    },

    narrowSideColumn: {
      type: Boolean,
      default: false
    },

    alignCenter: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="sass">
.page-section-columns
  position: relative
  display: flex
  flex-direction: column
  justify-content: center
  margin: -$marginLg/2 0
  &.is-align-center
    align-items: center
  +isDesktop
    flex-direction: row
    margin: -$marginLgWs/2
    &.is-narrow-aside
      .page-section-columns__column
        &:first-child
          flex-basis: 65%
        &:last-child
          flex-basis: 35%
    &.is-right-col-first
      .page-section-columns__column
        &:first-child
          order: 1
        &:last-child
          order: 0
  +viewport('>=tablet', '<desktop')
    margin: -$marginMdWs/2

.page-section-columns__column
  margin: $marginLg/2 0
  position: relative
  +isDesktop
    margin: 0 $marginLgWs/2
    &:not(:only-child)
      flex: 1
      flex-basis: 50%
  +isMobile
    flex: 1
    width: 100%
  +viewport('>=tablet', '<desktop')
    margin: 0 $marginMdWs/2
</style>
