<template>
  <two-column :rightFirst="true" bgStyle="hexagons" class="original-games-section" :above="true">
    <template v-slot:title><locale :path="localePath('title')" /></template>
    <template v-slot:text><locale :path="localePath('text')" /></template>
    <template v-slot:control>
      <page-button @click="scrollToNextSection">
        <locale :path="localePath('buttonText')" />
        <button-arrow />
      </page-button>
    </template>
    <template v-slot:content>
      <game-tile-grid width="3">
        <game-tile-grid-item
          v-for="(game, i) in games"
          :key="i"
        >
          <game-tile
            :logoSrc="game.hasLogo ? `/games/${game.key}Logo.png` : undefined"
            :bgSrc="`/games/${game.key}Bg.jpg`"
            :name="game.name"
            @click.native="$store.dispatch('showGamePodModal', game.key)"
          />
        </game-tile-grid-item>
      </game-tile-grid>
    </template>
    <template v-slot:textColFlourish>
      <flourish
        shape="section1"
        :scale="2"
        offsetY="-15%"
        :fillSpace="true"
        :desktopOnly="true"
      />
    </template>
    <template v-slot:sectionFlourish>
      <flourish
        shape="section11Up"
        :scale="0.85"
        :desktopOnly="true"
        :pullBottom="true"
        :pullLeft="true"
        margin="2rem"
        width="15vw"
      />
      <flourish
        shape="section1Mobile"
        :scale="1.1"
        offsetX="10%"
        :mobileOnly="true"
        :fillSpace="true"
      />
    </template>
  </two-column>
</template>

<script>
import section from '@/mixins/section'
import TwoColumn from '@/sections/TwoColumn'

export default {
  mixins: [
    section
  ],

  components: {
    TwoColumn
  },

  computed: {
    games () {
      const gameKeys = this.$constants.content.featuredGames
      const games = this.$locale('games')

      return gameKeys.map(key => ({
        key,
        ...games[key]
      }))
    }
  }
}
</script>

<style lang="sass">
.original-games-section__game-pod
  margin: auto
  z-index: 1

.original-games-section__game-pod-container
  position: absolute
  z-index: 3
  top: 0
  left: 0
  width: 100%
  height: 100%
  pointer-events: none

.original-games-section__game-pod-overlay
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  background-color: rgba(#07518E, 0)
  pointer-events: none
  transition: all $tsxDuration $tsxEasing
  &.is-visible
    pointer-events: auto
    background-color: rgba(#07518E, 0.65)

.original-games-section__game-pod-wrapper
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  .page-section-container
    height: 100%
  .page-section-container__inner
    display: flex
</style>
