<template>
  <font-awesome-icon :icon="iconArr" />
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true
    }
  },

  computed: {
    iconArr () {
      const parts = this.icon.split('-')
      return [parts.splice(0, 1), parts.join('-')]
    }
  }
}
</script>
