<template>
  <component :is="tagName" :to="to" :href="href" :target="target" class="footer-link">
    <span><slot /></span>
  </component>
</template>

<script>
import link from '@/mixins/link'

export default {
  mixins: [
    link
  ]
}
</script>

<style lang="sass">
.footer-link
  display: inline-flex
  cursor: pointer
  color: rgba($white, 0.6)
  > span
    transition: color $tsxDurationQuick $tsxEasing
    +hover
      color: $white
  &:not(:first-child)
    &::before
      pointer-events: none
      content: '|'
      display: inline-flex
      opacity: 0.5
</style>
