var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    'flourish': true,
    'is-wide': _vm.wide,
    'is-hidden-desktop': _vm.mobileOnly,
    'is-hidden-mobile': _vm.desktopOnly,
    'is-fill-space': _vm.fillSpace,
    'is-pull-top': _vm.pullTop,
    'is-pull-left': _vm.pullLeft,
    'is-pull-bottom': _vm.pullBottom,
    'is-pull-right': _vm.pullRight,
  },style:({
    margin: _vm.margin,
    transform: `translate(${_vm.offsetX}, ${_vm.offsetY}) translateY(${_vm.parallaxY}px) scale(${_vm.scale})`
  }),attrs:{"aria-hidden":"true"}},[_c('app-image',{style:({
      width: _vm.width,
      height: _vm.height
    }),attrs:{"src":`/flourishes/${_vm.shape}.svg`,"alt":"flourish"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }