<template>
  <div class="page-section-container">
    <div class="page-section-container__inner" :style="{ position: relativeContent ? 'relative' : 'static' }">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    relativeContent: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="sass">
.page-section-container
  width: 100%

.page-section-container__inner
  margin: 0 auto
  height: 100%
  max-width: 1140px
  padding: 0 $marginMd
  +isDesktop
    padding: 0 $marginLg
  +viewport('>=highdef')
    max-width: 1440px
</style>
