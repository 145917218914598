<template>
  <div class="contact-icons">
    <page-link
      v-for="(link, i) in $locale('links.social')"
      :key="i"
      :href="link.href"
      :mailTo="link.mailTo"
      target="_blank"
      class="contact-icons__icon"
      @click.native="openEmail($event, link.mailTo)"
    >
      <icon class="icon" :icon="link.icon" />
    </page-link>
  </div>
</template>

<script>
export default {
  methods: {
    openEmail (evt, mailto) {
      if (mailto) evt.preventDefault()
      else return

      window.location.href = mailto
    }
  }
}
</script>

<style lang="sass">
.contact-icons
  display: flex
  align-items: center
  justify-content: center

.contact-icons__icon
  +margin('sm')
  border-radius: $borderRadiusSm
  background: $lightFontColor
  width: 2.75em
  height: 2.75em
  display: flex
  align-items: center
  justify-content: center
  cursor: pointer
  box-shadow: 0 0 0 0 $red
  transition: all $tsxDurationQuick $tsxEasing
  +hover
    transform: scale(1.1)
    color: $red
    box-shadow: 0 0 0 4px $red
    .icon
      transform: scale(0.9)
      color: $red

  .icon
    width: 1.25em
    height: 1.25em
    color: $fontColor
    transition: all $tsxDurationQuick $tsxEasing
</style>
