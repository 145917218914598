<template>
  <component :is="tagName" :to="to" :href="href" :target="target" class="info-tile-link">
    <icon :icon="icon" class="info-tile-link__icon" />
    <span class="is-link"><slot /></span>
  </component>
</template>

<script>
import link from '@/mixins/link'

export default {
  mixins: [
    link
  ],

  props: {
    icon: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="sass">
.info-tile-link
  color: $fontColor
  cursor: pointer
  display: flex
  align-items: center

.info-tile-link__icon
  display: inline-flex
  margin-right: 0.5em
  transform: translateY(-2px)
  color: $fontColor
  width: 1.25rem
  height: 1.25rem
</style>
