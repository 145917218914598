<template>
  <span
    class="ajahsdjasd"
    :data-dfg="name"
    :data-erj="domain"
    :data-cod="tld"
    @click="openEmail"
  />
</template>

<script>
export default {
  props: {
    email: {
      type: String,
      required: true
    }
  },

  computed: {
    name () {
      return this.email.split('@')[0]
    },

    domain () {
      return this.email.split('@')[1].split('.')[0]
    },

    tld () {
      return this.email.split('@')[1].split('.')[1]
    }
  },

  methods: {
    openEmail () {
      window.location.href = `mailto:${this.name}@${this.domain}.${this.tld}`
    }
  }
}
</script>

<style lang="sass">
.ajahsdjasd::after
  content: attr(data-dfg) "@" attr(data-erj) "." attr(data-cod)
</style>
