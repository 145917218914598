var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.tagName,{tag:"component",class:{
    'game-tile': true,
    'has-panel': _vm.$slots.panel,
    'hover': true
  },attrs:{"to":_vm.to,"href":_vm.href,"target":_vm.target}},[_c('square-spacer'),_c('div',{class:{
      'game-tile__inner': true,
      'is-tile-bg': _vm.$slots.panel
    },style:({
      backgroundImage: `url('${_vm.bgSrc}')`
    })},[(_vm.logoSrc)?_c('app-image',{staticClass:"game-tile__logo",attrs:{"src":_vm.logoSrc,"alt":_vm.name}}):_vm._e(),(_vm.$slots.panel)?_c('div',{staticClass:"game-tile__panel"},[_vm._t("panel")],2):_vm._e()],1),(_vm.$slots.topRightFlourish)?_c('div',{staticClass:"game-tile__flourish is-top-right",attrs:{"aria-hidden":"true"}},[_vm._t("topRightFlourish")],2):_vm._e(),(_vm.$slots.bottomLeftFlourish)?_c('div',{staticClass:"game-tile__flourish is-bottom-left",attrs:{"aria-hidden":"true"}},[_vm._t("bottomLeftFlourish")],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }